#spinner {
  border: 12px solid #4169e1;
  border-radius: 50%;
  border-top: 12px solid #f0f0f0;
  width: 45px;
  height: 45px;
  /* margin-bottom: 15px; */
  animation: spin 2s linear infinite;
  margin: auto;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}